import Theme, {nib, gu, suncorp, apia, colorLightest, ing, priceline, nibLegacy, aami, qantas, cota, nsi, real, australianSeniors, aanz, worldNomads, tid, edi} from '@nib-components/theme';
import Grid from 'styled-components-grid';
import breakpoint from 'styled-components-breakpoint';
import React from 'react';
import styled from 'styled-components';
import Container from '@nib-components/container';
import PropTypes from 'prop-types';
import {useConfig} from '../../config';
const Colordiv = styled.div`
  ${breakpoint('md')`
    background-color: ${colorLightest};
  `}
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-around;
  ${breakpoint('md')`
    justify-content: center;
  `}
`;
const themes = {
  nib,
  'nib-iap': nibLegacy,
  iap: nibLegacy,
  gu,
  cignagu: gu,
  'gu-admin': gu,
  suncorp,
  apia,
  ing,
  'nib-health-services': nib,
  priceline,
  aami,
  qantas,
  nibau: nib,
  cota,
  ns: nsi,
  qan: qantas,
  aanz,
  nibnz: nib,
  real,
  australianSeniors,
  wn: worldNomads,
  tidau: tid,
  edi
};
export const PageContainer = ({children}) => {
  const config = useConfig();
  return (
    <div data-testid="login-container-component">
      <Theme theme={themes[config.brand]}>
        <Colordiv>
          <Container>
            <Grid halign="center">
              <Grid.Unit
                size={{
                  sm: 0.8,
                  md: 0.5,
                  lg: 0.4
                }}
              >
                <Wrapper>{children}</Wrapper>
              </Grid.Unit>
            </Grid>
          </Container>
        </Colordiv>
      </Theme>
    </div>
  );
};

PageContainer.propTypes = {
  children: PropTypes.object
};
