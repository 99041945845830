const contactLinkIds = {
  gaInlinkInside: 'ga-inlink-login-with-member-contact-us-inside-australia',
  gaInlinkOutside: 'ga-inlink-login-with-member-contact-us-outside-australia'
};

const englishTextConstants = (country) => {
  switch (country) {
    case 'NZ':
      return {
        contactUsDomesticText: 'Inside New Zealand',
        contactUsInternationalText: 'Outside New Zealand'
      };
    case 'UK':
      return {
        contactUsDomesticText: 'Inside the United Kingdom',
        contactUsInternationalText: 'Outside the United Kingdom'
      };
    case 'Anywhere':
      return {
        contactUsText: 'Phone'
      };
    case 'AU':
    default:
      return {
        contactUsDomesticText: 'Inside Australia',
        contactUsInternationalText: 'Outside Australia'
      };
  }
};

const brandContacts = (brand) => {
  switch (brand) {
    case 'aanz':
      return [
        {
          contactTitle: englishTextConstants('NZ').contactUsDomesticText,
          contactLink: 'tel:0800758758',
          contactLinkId: contactLinkIds.gaInlinkInside,
          contactText: '0800 758 758'
        },
        {
          contactTitle: englishTextConstants('NZ').contactUsInternationalText,
          contactLink: 'tel:+6492829633',
          contactLinkId: contactLinkIds.gaInlinkOutside,
          contactText: '64 9 282 9633'
        }
      ];
    case 'nibnz':
      return [
        {
          contactTitle: englishTextConstants('NZ').contactUsDomesticText,
          contactLink: 'tel:0800123642',
          contactLinkId: contactLinkIds.gaInlinkInside,
          contactText: '0800 123 642'
        },
        {
          contactTitle: englishTextConstants('NZ').contactUsInternationalText,
          contactLink: 'tel:+6499562613',
          contactLinkId: contactLinkIds.gaInlinkOutside,
          contactText: '+64 9 956 2613'
        }
      ];
    case 'gu':
      return [
        {
          contactTitle: englishTextConstants('AU').contactUsDomesticText,
          contactLink: 'tel:1800249966',
          contactLinkId: contactLinkIds.gaInlinkInside,
          contactText: '1800 249 966'
        },
        {
          contactTitle: englishTextConstants('AU').contactUsInternationalText,
          contactLink: 'tel:+61296924438',
          contactLinkId: contactLinkIds.gaInlinkOutside,
          contactText: '+61 2 9692 4438'
        }
      ];
    case 'gu-admin':
      return [
        {
          contactTitle: 'GU Health Member Relationship Team',
          contactLink: 'tel:1800249966',
          contactLinkId: contactLinkIds.gaInlinkInside,
          contactText: '1800 249 966'
        }
      ];
    case 'cignagu':
      return [
        {
          contactTitle: englishTextConstants('AU').contactUsDomesticText,
          contactLink: 'tel:1800124214',
          contactLinkId: contactLinkIds.gaInlinkInside,
          contactText: '1800 124 214'
        },
        {
          contactTitle: englishTextConstants('AU').contactUsInternationalText,
          contactLink: 'tel:+61296924436',
          contactLinkId: contactLinkIds.gaInlinkOutside,
          contactText: '+61 2 9692 4436'
        }
      ];
    case 'ing':
      return [
        {
          contactTitle: englishTextConstants('AU').contactUsDomesticText,
          contactLink: 'tel:1800111831',
          contactLinkId: contactLinkIds.gaInlinkInside,
          contactText: '1800 111 831'
        },
        {
          contactTitle: englishTextConstants('AU').contactUsInternationalText,
          contactLink: 'tel:+61290284077',
          contactLinkId: contactLinkIds.gaInlinkOutside,
          contactText: '+61 2 9028 4077'
        }
      ];
    case 'apia':
      return [
        {
          contactTitle: englishTextConstants('AU').contactUsDomesticText,
          contactLink: 'tel:135050',
          contactLinkId: contactLinkIds.gaInlinkInside,
          contactText: '13 50 50'
        },
        {
          contactTitle: englishTextConstants('AU').contactUsInternationalText,
          contactLink: 'tel:+61243312024',
          contactLinkId: contactLinkIds.gaInlinkOutside,
          contactText: '+61 2 4331 2024'
        }
      ];
    case 'suncorp':
      return [
        {
          contactTitle: englishTextConstants('AU').contactUsDomesticText,
          contactLink: 'tel:131155',
          contactLinkId: contactLinkIds.gaInlinkInside,
          contactText: '13 11 55'
        },
        {
          contactTitle: englishTextConstants('AU').contactUsInternationalText,
          contactLink: 'tel:+61733621222',
          contactLinkId: contactLinkIds.gaInlinkOutside,
          contactText: '+61 7 3362 1222'
        }
      ];
    case 'aami':
      return [
        {
          contactTitle: englishTextConstants('AU').contactUsDomesticText,
          contactLink: 'tel:132244',
          contactLinkId: contactLinkIds.gaInlinkInside,
          contactText: '13 22 44'
        },
        {
          contactTitle: englishTextConstants('AU').contactUsInternationalText,
          contactLink: 'tel:+61385201300',
          contactLinkId: contactLinkIds.gaInlinkOutside,
          contactText: '+61 3 8520 1300'
        }
      ];
    case 'qantas':
      return [
        {
          contactTitle: englishTextConstants('AU').contactUsDomesticText,
          contactLink: 'tel:134960 ',
          contactLinkId: contactLinkIds.gaInlinkInside,
          contactText: '13 49 60'
        },
        {
          contactTitle: englishTextConstants('AU').contactUsInternationalText,
          contactLink: 'tel:+61283767600',
          contactLinkId: contactLinkIds.gaInlinkOutside,
          contactText: '+61 2 8376 7600'
        }
      ];
    case 'nib-health-services':
      return [
        {
          contactTitle: 'Email nib Health Services',
          contactLink: 'mailto:healthservices@nib.com.au',
          contactLinkId: contactLinkIds.gaInlinkInside,
          contactText: 'healthservices@nib.com.au'
        }
      ];
    case 'priceline':
      return [
        {
          contactTitle: englishTextConstants('AU').contactUsDomesticText,
          contactLink: 'tel:1300070761',
          contactLinkId: contactLinkIds.gaInlinkInside,
          contactText: '1300 070 761'
        },
        {
          contactTitle: englishTextConstants('AU').contactUsInternationalText,
          contactLink: 'tel:+61296924484',
          contactLinkId: contactLinkIds.gaInlinkOutside,
          contactText: '+612 9692 4484'
        }
      ];
    case 'ns':
    case 'nibau':
    case 'cota':
    case 'qan':
      return [
        {
          contactTitle: englishTextConstants('AU').contactUsDomesticText,
          contactLink: 'tel:1300410272',
          contactLinkId: contactLinkIds.gaInlinkInside,
          contactText: '1300 410 272'
        },
        {
          contactTitle: englishTextConstants('AU').contactUsInternationalText,
          contactLink: 'tel:+61292343193',
          contactLinkId: contactLinkIds.gaInlinkOutside,
          contactText: '+61 2 9234 3193'
        }
      ];
    case 'real':
      return [
        {
          contactTitle: englishTextConstants('AU').contactUsDomesticText,
          contactLink: 'tel:1300832028',
          contactLinkId: contactLinkIds.gaInlinkInside,
          contactText: '1300 832 028'
        },
        {
          contactTitle: englishTextConstants('AU').contactUsInternationalText,
          contactLink: 'tel:+611300832028',
          contactLinkId: contactLinkIds.gaInlinkOutside,
          contactText: '+61 1300 832 028'
        }
      ];
    case 'australianSeniors':
      return [
        {
          contactTitle: englishTextConstants('AU').contactUsDomesticText,
          contactLink: 'tel:1300029836',
          contactLinkId: contactLinkIds.gaInlinkInside,
          contactText: '1300 029 836'
        },
        {
          contactTitle: englishTextConstants('AU').contactUsInternationalText,
          contactLink: 'tel:+611300029836',
          contactLinkId: contactLinkIds.gaInlinkOutside,
          contactText: '+61 1300 029 836'
        }
      ];
    case 'wn':
      return [
        {
          contactTitle: englishTextConstants('UK').contactUsDomesticText,
          contactLink: 'tel:08002605081',
          contactLinkId: contactLinkIds.gaInlinkInside,
          contactText: '0800 260 5081'
        },
        {
          contactTitle: englishTextConstants('UK').contactUsInternationalText,
          contactLink: 'tel:+353212378000',
          contactLinkId: contactLinkIds.gaInlinkOutside,
          contactText: '+353 21 237 8000'
        }
      ];

    case 'nib':
    case 'tidau':
      return [
        {
          contactTitle: englishTextConstants('AU').contactUsDomesticText,
          contactLink: 'tel:1300843843',
          contactLinkId: contactLinkIds.gaInlinkInside,
          contactText: '1300 843 843'
        },
        {
          contactTitle: englishTextConstants('AU').contactUsInternationalText,
          contactLink: 'tel:+61282561537',
          contactLinkId: contactLinkIds.gaInlinkOutside,
          contactText: '+61 2 8256 1537'
        }
      ];
    case 'edi':
      return [
        {
          contactTitle: englishTextConstants('Anywhere').contactUsText,
          contactLink: 'tel:1300101234',
          contactLinkId: contactLinkIds.gaInlinkInside,
          contactText: '1300 10 1234'
        }
      ];
    default:
      return [
        {
          contactTitle: englishTextConstants('AU').contactUsDomesticText,
          contactLink: 'tel:131642',
          contactLinkId: contactLinkIds.gaInlinkInside,
          contactText: '13 16 42'
        },
        {
          contactTitle: englishTextConstants('AU').contactUsInternationalText,
          contactLink: 'tel:+61296924300',
          contactLinkId: contactLinkIds.gaInlinkOutside,
          contactText: '+61 2 9692 4300'
        }
      ];
  }
};

export const contactUsConstants = (brand) => {
  return {
    ...englishTextConstants,
    contacts: brandContacts(brand)
  };
};
