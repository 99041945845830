import {metrics} from 'react-metrics';
import compose from 'lodash/fp/compose';
import trackPage from '@nib/track-page';
import nibMetricsConfig from '../metrics/nibMetricsConfig';
import nibMetricsOptions from '../metrics/nibMetricsOptions';
import mapMetricsToProps from '../metrics/mapMetricsToProps';

/**
 * HOC to wrap each page for tealium/analytics tracking
 *
 * @param   {object}  options                 The tealium datalayer attributes
 * @param   {string}  options.channel         The channel
 * @param   {string}  options.siteName        The site name
 * @param   {string}  options.siteSection     The site section
 * @param   {string}  options.pageType        The page type
 * @param   {string}  options.pageName        The page name
 * @return  {function}
 */
const enhance = (options = {}) => compose(metrics(nibMetricsConfig, nibMetricsOptions), trackPage(mapMetricsToProps(options)));

export default enhance;
