import React from 'react';
import Heading from '@nib-components/heading';
import Copy from '@nib-components/copy';
import { Margin } from 'styled-components-spacing';
import { Header } from '../Layout/Header';
import { stringConstants } from '../../utils/dataConstants';
import { BodyContentWrapper } from '../Layout/BodyContentWrapper';
import { ErrorDisplay } from '../ErrorDisplay/ErrorDisplay';
import { ContactsDisplay } from '../ContactUs/ContactsDisplay';

export const UnrecoverableError = () => {
  return (
    <>
      <Header>
        <Heading size={2} align="center">
          {stringConstants.pageTitle}
        </Heading>
      </Header>
      <BodyContentWrapper>
        <Heading size={3} center>
          {stringConstants.pageSubTitle}
        </Heading>
        <ErrorDisplay />
        <Margin top={5}>
          <Margin bottom={3}>
            <Copy>{stringConstants.contactUsText}</Copy>
          </Margin>
          <ContactsDisplay />
        </Margin>
      </BodyContentWrapper>
    </>
  );
};

