import React from 'react';
import {colorLighter, colorWhite} from '@nib-components/theme';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import {p} from 'styled-components-spacing';
import PropTypes from 'prop-types';
const Shadow = styled.div`
  ${breakpoint('md')`
  box-shadow: ${colorLighter} 0 4px 8px 0;
  border-radius: 5px;
  background-color:${colorWhite};
  ${p(7)}`}
  ${p(4)}
`;

const Padder = styled.div`
  ${breakpoint('md')`
  display:none;
`}
  display:block;
`;

export const BodyContentWrapper = ({children}) => (
  <React.Fragment>
    <Shadow>{children}</Shadow>
    <Padder />
  </React.Fragment>
);

BodyContentWrapper.propTypes = {
  children: PropTypes.object
};
