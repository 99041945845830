import qs from 'querystring';

export const extractDetailsFromQuery = () => {
  let searchQuery = typeof window !== 'undefined' ? window.location.search : '';

  if (searchQuery[0] === '?') {
    searchQuery = searchQuery.slice(1, searchQuery.length);
  }

  if (searchQuery[searchQuery.length - 1] === '/') {
    searchQuery = searchQuery.slice(0, -1);
  }
  const queryParams = qs.parse(searchQuery);

  const errorDescriptionFromQuery = queryParams.error_description;
  const trackingCodeFromQuery = queryParams.tracking;

  return {
    errorDescriptionFromQuery,
    trackingCodeFromQuery
  };
};
