import React from 'react';
import metrics from '../metrics';
import {ConfigProvider} from '../config';
import {PageLayout} from '../components/Layout';
import {UnrecoverableError} from '../components/UnrecoverableError/UnrecoverableError';

const IndexPage = () => {
  return (
    <ConfigProvider>
      <PageLayout>
        <UnrecoverableError />
      </PageLayout>
    </ConfigProvider>
  );
};

export default metrics({pageName: 'auth0 unrecoverable error page'})(IndexPage);
