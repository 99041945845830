import Copy from '@nib-components/copy';
import Heading from '@nib-components/heading';
import React from 'react';
import styled from 'styled-components';
import {Margin} from 'styled-components-spacing';

import {stringConstants} from '../../utils/dataConstants';
import {extractDetailsFromQuery} from './queryInterpreter';

const WrappedCopy = styled(Copy)`
  overflow-wrap: break-word
  word-wrap: break-word
`;

export const ErrorDisplay = () => {
  const errorDetails = extractDetailsFromQuery();

  return (
    <Margin top={5}>
      <Heading size={4}>{stringConstants.errorTitle} </Heading>
      <Margin bottom={3}>
        <WrappedCopy>{errorDetails.errorDescriptionFromQuery}</WrappedCopy>
      </Margin>
      <Copy>
        {stringConstants.errorTrackingNumberLabel} {errorDetails.trackingCodeFromQuery}
      </Copy>
    </Margin>
  );
};
