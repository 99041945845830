/* global process */
import React, {createContext, useContext} from 'react';
import PropTypes from 'prop-types';

export const ConfigContext = createContext({});
const brand = process.env.GATSBY_BRAND || 'nib';

export const getFaviconForBrand = () => {
  switch (brand) {
    case 'apia':
      return 'https://www.apia.com.au/etc/designs/suncorp/apia/design/favicon.ico';
    case 'ing':
      return 'https://www.ing.com.au/ico/favicon.png';
    case 'suncorp':
      return 'https://www.suncorp.com.au/etc/designs/suncorp/suncorp/global-design/favicon.ico';
    case 'gu':
    case 'gu-admin':
      return 'https://www.guhealth.com.au/files/favicon.6d1abbea.ico';
    case 'priceline':
      return 'https://d2x23mhg1uc9un.cloudfront.net/skin/frontend/priceline/default/images/favicons/favicon.ico';
    case 'aami':
      return 'https://www.aami.com.au/etc/designs/suncorp/aami/design/favicon.ico';
    case 'qantas':
      return 'https://www.qantas.com.au/favicon.ico';
    case 'cota':
    case 'ns':
    case 'real':
      return `https://static.nib.com.au/images/${brand}/favicon.ico`;
    case 'australianSeniors':
      return `https://static.nib.com.au/images/australian-seniors/favicon.ico`;
    case 'qan':
      return 'https://static.nib.com.au/images/qantas/favicon.ico';
    case 'tidau':
      return 'https://static.nib.com.au/images/tid/favicon.ico';
    case 'wn':
      return 'https://static.nib.com.au/images/world-nomads/favicon.ico';
    case 'nib':
    case 'nibau':
    case 'aanz':
    case 'nibnz':
    default:
      // eslint-disable-next-line consistent-return, no-undefined
      return undefined;
  }
};

export const ConfigProvider = ({children}) => (
  <ConfigContext.Provider
    value={{
      brand
    }}
  >
    {children}
  </ConfigContext.Provider>
);

ConfigProvider.propTypes = {
  children: PropTypes.object
};

export const useConfig = () => useContext(ConfigContext);
