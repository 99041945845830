/* eslint-disable */
let config = {};

if (typeof window === 'undefined') {
  config.tealium = {};
  config.tealium.account = 'nib';
  config.tealium.profile = 'main';
  config.tealium.environment = 'qa';
} else {
  config = window.__CONFIG__;
}
/* eslint-enable */

export const tealiumConfig = config.tealium;
export default config;
