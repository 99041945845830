import React from 'react';

import {Margin} from 'styled-components-spacing';
import Grid from 'styled-components-grid';
import Copy, {Bold} from '@nib-components/copy';
import Link from '@nib-components/link';

import {useConfig} from '../../config';
import {contactUsConstants} from './constants';

export const ContactsDisplay = () => {
  const config = useConfig();
  const constants = contactUsConstants(config.brand);
  return (
    <>
      {constants.contacts.map((contact, index) => (
        <Margin bottom={constants.contacts.length === index + 1 ? 0 : 5} key={index}>
          <Grid>
            <Grid.Unit size={0.5}>
              <Copy>
                <Bold>{contact.contactTitle}</Bold>
              </Copy>
            </Grid.Unit>
            <Grid.Unit size={0.5}>
              <Link id={contact.contactLinkId} href={contact.contactLink}>
                {contact.contactText}
              </Link>
            </Grid.Unit>
          </Grid>
        </Margin>
      ))}
    </>
  );
};
