import {graphql, StaticQuery} from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';
import {PageContainer} from './PageContainer';
export const PureLayout = ({children, data}) => (
  <div>
    <Helmet>
      <title>{data.site.siteMetadata.title}</title>
      <meta name="description" content={data.site.siteMetadata.description} />
    </Helmet>
    <div>
      <PageContainer>{children}</PageContainer>
    </div>
  </div>
);

PureLayout.propTypes = {
  children: PropTypes.object,
  data: PropTypes.shape({
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.string.isRequired
      }).isRequired
    }).isRequired
  }).isRequired
};

const PageLayout = ({children}) => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `}
    render={(data) => <PureLayout data={data}>{children}</PureLayout>}
  />
);

PageLayout.propTypes = {
  children: PropTypes.object
};

export {PageLayout};
